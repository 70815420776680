<script setup lang="ts">
  import { useLocale } from '@tundr/i18n';
  import { RoutingView } from '@tundr/routing';
  import { Sidebar, SidebarItemElement, SidebarItemType } from '@tundr/sidebar';
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { Divider, PageLayout } from '@tundr/ui-kit';
  import { appRoutesDictionary } from '../../core/router/routes.dictionary.ts';
  import UserMenu from '../../shared/components/UserMenu/UserMenu.vue';
  import { sidebarHeaderSectionClass } from './AppRouter.css';

  const { t } = useLocale('common');

  const menuItems: SidebarItemElement[] = [
    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('sections.manage'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.users'),
      to: { name: appRoutesDictionary.users.root.name },
      icon: UNICONS_ICONS.USER,
      isFontIcon: true,
    },

    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.suppliers'),
      to: { name: appRoutesDictionary.suppliers.root.name },
      icon: ICONS.GROUPS,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.companies'),
      to: { name: appRoutesDictionary.company.root.name },
      icon: ICONS.HEADQUARTERS,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.reimbursements'),
      to: { name: appRoutesDictionary.reimbursement.root.name },
      icon: ICONS.BENEFIT,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.bank_transfers'),
      to: { name: appRoutesDictionary.bankTransfers.root.name },
      icon: ICONS.CARD,
    },

    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('sections.reports'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.card_orders'),
      to: { name: appRoutesDictionary.cardOrder.root.name },
      icon: UNICONS_ICONS.CREDIT_CARD,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.cards_shipped'),
      to: { name: appRoutesDictionary.reports.root.name },
      isFontIcon: true,
      icon: UNICONS_ICONS.BOX,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.subscriptions'),
      to: { name: appRoutesDictionary.subscription.root.name },
      icon: UNICONS_ICONS.RECEIPT,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.over_budget_employees'),
      to: { name: appRoutesDictionary.overBudgetEmployees.root.name },
      icon: UNICONS_ICONS.ANALYSIS,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.supplier_vouchers'),
      to: { name: appRoutesDictionary.supplierVouchers.root.name },
      icon: UNICONS_ICONS.RECEIPT,
      isFontIcon: true,
    },
    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('sections.billing'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.fringe_orders'),
      to: { name: appRoutesDictionary.billing.root.name },
      isFontIcon: true,
      icon: UNICONS_ICONS.PARCEL,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.payments'),
      to: { name: appRoutesDictionary.payments.root.name },
      icon: ICONS.TRANSFER_BANK,
    },
    {
      type: SidebarItemType.SECTION_HEADER,
      label: t('sections.fringe_merchants'),
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.update_mid'),
      to: { name: appRoutesDictionary.fringe.updateMid.name },
      isFontIcon: true,
      icon: UNICONS_ICONS.REFRESH,
    },
    {
      type: SidebarItemType.MENU_ITEM,
      label: t('sections.search_mid'),
      to: { name: appRoutesDictionary.fringe.searchMid.name },
      isFontIcon: true,
      icon: UNICONS_ICONS.SEARCH_ALT,
    },
  ];
</script>

<template>
  <PageLayout>
    <template #sidebar>
      <Sidebar :menu-items="menuItems">
        <template #header="{ collapsed }">
          <div :class="sidebarHeaderSectionClass">
            <UserMenu :collapsed="collapsed" />
            <Divider v-if="!collapsed" />
          </div>
        </template>
      </Sidebar>
    </template>
    <RoutingView />
  </PageLayout>
</template>
