<script setup lang="ts">
  import { ICONS } from '@tundr/theme-assets/font-icon/icons';
  import { UNICONS_ICONS } from '@tundr/theme-assets/unicons/unicons-icons';
  import { FontIcon, Icon, Tooltip } from '@tundr/ui-kit';
  import { computed } from 'vue';
  import {
    RouteLocationNamedRaw,
    RouteLocationPathRaw,
    useRouter,
  } from 'vue-router';
  import { useSidebarStore } from '../../store/sidebar.store';
  import {
    sidebarItemContainerClass,
    sidebarItemIconClass,
    sidebarItemSelectedClass,
  } from './SidebarMenuItem.css';
  import { SidebarMenuItemProps } from './SidebarMenuItem.types.ts';

  function isRouteLocationPath(
    route: RouteLocationPathRaw | RouteLocationNamedRaw,
  ): route is RouteLocationPathRaw {
    return Object.hasOwn(route, 'path');
  }

  const props = defineProps<SidebarMenuItemProps>();

  const sidebarStore = useSidebarStore();

  const router = useRouter();

  const menuItemRoutePath = computed<string | undefined>(() => {
    if (typeof props.to === 'string') {
      return props.to;
    }
    if (props.to && isRouteLocationPath(props.to)) {
      return props.to.path;
    }
    const found = props.to && router.resolve(props.to);
    return found && found.path;
  });

  const isSelected = computed(() => {
    return (
      menuItemRoutePath.value &&
      router.currentRoute.value.path.includes(menuItemRoutePath.value)
    );
  });

  const siderbarItemClasses = computed(() => [
    sidebarItemContainerClass,
    { [sidebarItemSelectedClass]: isSelected.value },
  ]);

  const onNavigate = ($event: MouseEvent) => {
    $event.preventDefault();
    if (props.to) {
      router.push(props.to);
    }
    if (props.onClick) {
      props.onClick();
    }
  };
</script>

<template>
  <a
    :class="siderbarItemClasses"
    @click="onNavigate"
    role="link"
    :href="to as string"
  >
    <template v-if="sidebarStore.collapsed">
      <Tooltip :label="label" interactive>
        <div>
          <Icon
            v-if="!isFontIcon"
            :class="sidebarItemIconClass"
            :iconName="icon as ICONS"
          />
          <FontIcon
            v-if="isFontIcon"
            :class="sidebarItemIconClass"
            :iconName="icon as UNICONS_ICONS"
          />
        </div>
      </Tooltip>
    </template>
    <template v-if="!sidebarStore.collapsed">
      <Icon
        v-if="!isFontIcon"
        :class="sidebarItemIconClass"
        :iconName="icon as ICONS"
      />
      <FontIcon
        v-if="isFontIcon"
        :class="sidebarItemIconClass"
        :iconName="icon as UNICONS_ICONS"
      />
      <span>{{ label }}</span>
    </template>
  </a>
</template>
