import { RouteRecordRaw } from 'vue-router';
import { supplierVouchersRoutesDictionary } from './supplier-vouchers-routes.dictionary.ts';

export const supplierVouchersRoutesConfig: RouteRecordRaw = {
  ...supplierVouchersRoutesDictionary.supplierVouchers.root,
  redirect: {
    name: supplierVouchersRoutesDictionary.supplierVouchers.list.name,
  },
  children: [
    {
      ...supplierVouchersRoutesDictionary.supplierVouchers.list,
      component: () =>
        import('../pages/List/SupplierVoucherReportsListPage.vue'),
    },
  ],
};
